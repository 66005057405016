<template>
  <WeContainer card="">
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-shopping-basket"></i>
        <span>Günlük Sipariş Raporları</span>
      </h5>
    </WeCard>
    <WeTableSearch
      v-on:search="onSearch"
      v-on:clear="onFilterClear"
      v-bind:show-icon="false"
      header-class="bg-white text-dark"
      header-text-class="text-dark h6"
      v-bind:show-on-load="true"
    >
      <div slot="detail-search">
        <div class="row mb-3">
          <!-- Left -->
          <div class="col-12 col-lg-6">
            <!-- Created At -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Tarih"
                placeholder="Tarih"
                v-bind:date-range="true"
                v-model="filter.dates"
                selected-shortcut="thisMonth"
              />
            </div>
            <!-- Created At -->

            <!-- Order Status -->
            <div class="d-block mb-3" v-if="marketplaceConfig">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="marketplace"
                label="Sipariş Kaynağı"
                select-label="title"
                placeholder="Sipariş Kaynağı"
                v-bind:multiple="true"
                v-bind:close-on-select="false"
                v-bind:option-prop="getMarketplaceList"
                v-model="filter.marketplaces"
              />
            </div>
            <!-- Order Status -->
          </div>
          <!-- Left -->

          <!-- Right -->
          <div class="col-12 col-lg-6">
            <!-- Order Status -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="order_status"
                label="Sipariş Durumu"
                placeholder="Sipariş Durumu"
                v-bind:multiple="true"
                v-bind:close-on-select="false"
                v-bind:option-prop="order.orderTypes"
                v-model="filter.order_status"
                v-bind:hint="orderStatusHint"
              />
            </div>
            <!-- Order Status -->

            <!-- Payment Type -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="payment_type"
                label="Ödeme Yöntemi"
                placeholder="Ödeme Yöntemi"
                v-bind:multiple="true"
                v-bind:close-on-select="false"
                v-bind:option-prop="order.paymentTypes"
                v-model="filter.payment_types"
              />
            </div>
            <!-- Payment Type -->
          </div>
          <!-- Right -->
        </div>
      </div>
    </WeTableSearch>
    <WeCard>
      <div class="position-relative" style="min-height: 250px" v-if="loading">
        <WeLoading />
      </div>
      <div v-else v-bind:id="printConfig.id">
        <span v-print="printConfig" ref="printButton"> </span>
        <div class="text-center print">
          <h5 class="font-weight-bold">{{ getFileName }}</h5>
        </div>
        <WeTable
          v-bind:index="false"
          v-bind:columns="getColumns"
          v-bind:data="list"
          v-bind:actions="actions"
          v-bind:exportables="exportables"
          v-on:on-export="onExport"
          v-on:on-action="onAction"
          v-bind:paginatable="paginatable"
          v-bind:pageDetails="pageDetails"
          v-bind:limitable="limitable"
          v-bind:searchable="searchable"
          v-bind:show-table-total="true"
          v-bind:table-total="tableTotalPrice"
          v-bind:hide-table-total-print="true"
          total-column="day_total"
        />
        <div class="col-12 text-right" v-if="!paginatable">
          <span class="font-weight-bold"
            >Toplam Tutar : {{ tableTotalPrice | moneyFormat }}
          </span>
        </div>
      </div>
    </WeCard>
    <OrderReportDetail
      v-if="showModal"
      v-bind:detail="detail"
      v-bind:loading="modalLoading"
      v-on:close="onClose"
    />
  </WeContainer>
</template>
<script>
import { mapState, mapActions } from "vuex";
const OrderReportDetail = () => import("./views/OrderReportDetail/Index");

export default {
  name: "DailyOrderReports",
  components: {
    OrderReportDetail,
  },
  data() {
    return {
      loading: true,
      modalLoading: true,
      showModal: false,
      tableTotalPrice: 0,
      paginatable: true,
      pageDetails: true,
      limitable: true,
      searchable: true,
      columns: [
        { name: "date", th: "Sipariş Tarihi", type: "date" },
        {
          name: "ecommerce_quantity",
          th: "E-Ticaret Sipariş Adeti",
          type: "integer",
        },
        {
          name: "ecommerce_price",
          th: "E-Ticaret Sipariş Toplamı",
          type: "currency",
        },
        { name: "total_returns", th: "İptal & İade Toplamı", type: "currency" },
        { name: "day_total", th: "Gün Toplamı", type: "currency" },
      ],
      actions: [
        {
          icon: "fas fa-eye",
          class: "btn-outline-indigo",
          action: "detail",
          tooltip: "Rapor Detayı",
        },
      ],
      list: [],
      detail: null,
      filter: {
        dates: {
          start: null,
          end: null,
        },
        date: null,
        order_status: [],
        payment_types: [],
        marketplaces: [],
      },
      exportables: ["excel", "pdf", "print"],
      printConfig: {
        id: "printDailyOrderList",
        popTitle: "Günlük Sipariş Raporları",
      },
    };
  },
  methods: {
    ...mapActions("order", ["getOrderTypes", "getPaymentTypes"]),
    ...mapActions("reports", ["makeSearch", "show"]),
    ...mapActions("tableExport", ["exportData"]),
    getFilter(copyDates = true) {
      let copy = helper.clone(this.filter);
      copy.dates = [];

      if (
        copyDates &&
        this.filter.dates &&
        this.filter.dates.start &&
        this.filter.dates.end
      ) {
        Object.entries(this.filter.dates).forEach(([key, value]) => {
          if (value) {
            copy.dates.push(value);
          }
        });
      } else {
        delete copy.dates;
      }

      return copy;
    },
    onSearch() {
      this.loading = true;
      this.makeSearch({
        routeKey: "dailyOrders",
        filter: this.getFilter(),
        onSuccess: (result) => {
          this.list = result.data.items;
          this.tableTotalPrice = result.data.table_total;
          this.loading = false;
        },
      });
    },
    onAction(data) {
      if (data.key == "detail") {
        this.showDetail(data.row);
      }
    },
    showDetail(row) {
      this.detail = {};
      this.detail.title =
        new Date(row.date).toLocaleDateString("tr-TR") + " - Sipariş Listesi";
      this.detail.date = row.date;
      this.detail.filter = this.getFilter(false);
      this.showModal = true;
      this.getReportDetail(row.date);
    },
    getReportDetail(date) {
      this.modalLoading = true;
      this.show({
        routeKey: "dailyOrderDetail",
        id: date,
        filter: this.getFilter(false),
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.detail.orders = result.data.items || [];
            this.detail.tableTotalPrice = localization.getMoneyFormat(
              null,
              result.data.table_total
            );
          } else {
            this.$swal({
              title: "Hatalı İşlem",
              icon: "error",
              confirmButtonText: "Tamam",
            });
          }
        },
        onFinish: () => {
          this.modalLoading = false;
        },
      });
    },
    onClose() {
      this.showModal = false;
    },
    onFilterClear() {
      this.filter = {
        dates: {
          start: null,
          end: null,
        },
        date: null,
        order_status: [],
        payment_types: [],
        marketplaces: [],
      };
      this.onSearch();
    },
    onExport(data) {
      if (this.list && this.list.length) {
        if (data.name !== "print") {
          let fileName = "Günlük Sipariş Raporları";

          if (
            this.filter.dates &&
            this.filter.dates.start &&
            this.filter.dates.end
          ) {
            let startDate = new Date(
              this.filter.dates.start
            ).toLocaleDateString("tr-TR");
            let endDate = new Date(this.filter.dates.end).toLocaleDateString(
              "tr-TR"
            );

            fileName = startDate + " - " + endDate + " - " + fileName;
          }

          this.exportData({
            route: "report/daily-orders/export",
            exportable: data,
            fileName: fileName,
            filter: {
              ...this.getFilter(),
              type: data.name,
            },
          });
        } else {
          this.setPrintableSettings(true);
          this.$refs.printButton.click();

          setTimeout(() => {
            this.setPrintableSettings(false);
          }, 100);
        }
      } else {
        this.$swal({
          title: "Dışarı aktarılacak veya Yazdırılacak veri mevcut değil",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      }
    },
    setPrintableSettings(print = false) {
      let marketplaceQuantity = print
        ? "PY Sipariş Adeti"
        : "Pazaryeri Sipariş Adeti";
      let marketplaceTotalAmount = print
        ? "PY Sipariş Toplamı"
        : "Pazaryeri Sipariş Toplamı";

      this.getColumns.map((item) => {
        if (item.name == "marketplace_quantity") {
          item.th = marketplaceQuantity;
        } else if (item.name == "marketplace_total_amount") {
          item.th = marketplaceTotalAmount;
        }

        return item;
      });

      this.paginatable = !print;
      this.pageDetails = !print;
      this.limitable = !print;
      this.searchable = !print;

      this.list.sort();
    },
  },
  computed: {
    ...mapState({
      order: (state) => state.order,
      config: (state) => state.session.config,
      marketplace: (state) => state.marketplace,
    }),
    getFileName() {
      const defaultName = "Sipariş Raporları";
      let result = "";

      if (
        this.filter.dates &&
        this.filter.dates.start &&
        this.filter.dates.end
      ) {
        let startDate = this.filter.dates.start;
        let endDate = this.filter.dates.end;

        startDate = new Date(startDate).toLocaleDateString("tr-TR");
        endDate = new Date(endDate).toLocaleDateString("tr-TR");

        result = `${startDate} - ${endDate} Sipariş Raporları`;
      } else {
        result = defaultName;
      }

      return result;
    },
    getColumns() {
      let columns = this.columns;
      let customColumns = [];

      if (this.marketplaceConfig) {
        customColumns = [
          {
            name: "marketplace_quantity",
            th: "Pazaryeri Sipariş Adeti",
            type: "integer",
          },
          {
            name: "marketplace_total_amount",
            th: "Pazaryeri Sipariş Toplamı",
            type: "currency",
          },
        ];
      }
      if (customColumns.length) {
        for (let i = customColumns.length - 1; i >= 0; i--) {
          const custom = customColumns[i];
          columns.splice(1, 0, custom);
        }
      }
      return columns;
    },
    marketplaceConfig() {
      return (
        this.config["site.marketplace_integration"] == "1" ||
        this.config["site.marketplace_integration"] == 1
      );
    },
    orderStatusHint() {
      return `Varsayılan Sipariş Durumu
        <ul class="text-left mb-0">
          <li class="font-weight-bold">Ödeme Yapıldı</li>
          <li class="font-weight-bold">Onaylandı</li>
          <li class="font-weight-bold">Kargo Hazırlanıyor</li>
          <li class="font-weight-bold">Kargoya Verildi</li>
          <li class="font-weight-bold">Teslim Edildi</li>
        </ul>`;
    },
    getMarketplaceList() {
      let marketplaceList = this.marketplace.list;
      let eCommerce = {
        id: "e-commerce",
        title: "Pazaryerinde Olmayanlar",
      };

      const result = [eCommerce, ...marketplaceList];
      return result;
    },
  },
  mounted() {
    if (this.marketplaceConfig) {
      this.$store.dispatch("marketplace/getList");
    }
    this.getOrderTypes({
      onFinish: () => {
        this.getPaymentTypes({
          onFinish: () => {
            this.onSearch();
          },
        });
      },
    });
  },
};
</script>
